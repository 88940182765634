<template>
	<div class="container">
		<div class="content flex flex-between" id="boxActive" :class="{'isActive' : isActive}">
			<div class="ft-nav noticeNav">
				<router-link :to="{name:'NewsList'}" class="nav-items news">
					<div class="iconbox"></div>
					<span>资讯讯息</span>
				</router-link>
				<router-link :to="{name:'ExamArrangement'}" class="nav-items examArrangement">
					<div class="iconbox"></div>
					<span>考试安排</span>
				</router-link>
				<router-link :to="{name:'ProjectPolicy'}" class="nav-items projectPolicy">
					<div class="iconbox"></div>
					<span>项目政策</span>
				</router-link>
				<router-link :to="{name:'ProblemSolving'}" class="nav-items problemSolving">
					<div class="iconbox"></div>
					<span>问题答疑</span>
				</router-link>
				
			</div>
			<div class="rt-main">
				<router-view></router-view>		
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				isActive:'',
			}
		},
		mounted: function() {
			window.addEventListener('scroll', this.handleScroll, true); // 监听（绑定）滚轮滚动事件
		},
		methods: {
			handleScroll(){
					const boxDom=document.querySelector('#boxActive');
					let fullHeight=document.body.clientHeight;
			　　let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
			　　let offsetTop = boxDom?boxDom.offsetTop:0; // 要滚动到顶部吸附的元素的偏移量
					this.isActive = scrollTop > offsetTop ? true : false;
			}
		},
		destroyed: function() {
			window.removeEventListener('scroll', this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
		}
	}
</script>

<style lang="less" scoped>
	.container{
		padding: 30px;
		background-color: #F5F5F5;
		.content{
			width: var(--container-width);
			margin: 0 auto;
			.ft-nav{
				width: 200px;
				height: 250px;
				background: white;				
				border-radius: 10px;
				overflow: hidden;
			}
			.noticeNav{
				padding: 10px;
				.nav-items{
					display: flex;
					justify-content: flex-start;
					width: 100%;
					height: 50px;
					padding: 9px 25px;
					margin-bottom: 10px;
					background: white;
					color: #333333;
					border-radius: 10px;
					text-decoration: none;
					.iconbox{
						background-repeat: no-repeat;
						background-position: 0 -32px;
						background-size: 32px 64px;
						width: 32px;
						height: 32px;
						margin-right: 5px;
						overflow: hidden;
					}
					span{
						display: blck;
						height: 32px;
						line-height: 32px;
						font-size: 16px;
						color: #333333;
					}
				}
				.examArrangement .iconbox{
					background-image: url(../../../assets/icons/icon-test-mixture.png);					
				}
				.projectPolicy .iconbox{
					background-image: url(../../../assets/icons/icon-project-mixture.png);					
				}
				.problemSolving .iconbox{
					background-image: url(../../../assets/icons/icon-question-mixture.png);					
				}
				.news .iconbox{
					background-image: url(../../../assets/icons/icon-news.png);					
				}
				.nav-items.router-link-active{
					background: #d71a18;
					.iconbox{
						background-position: 0 0;
					}
					span{
						color: white;
					}
				}
				
			}
			.rt-main{
				width: 1070px;
				
			}
			
		}
		.isActive{		
			.ft-nav{
				position: fixed;
				top: 20px;
				z-index: 9;
			}
			.rt-main{
				margin-left: 230px;
			}
		}
	}
</style>
